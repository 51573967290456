<!--
 * @Descripttion: 登录页
 * @Author: zyr
 * @Date: 2021-05-27 20:59:08
 * @LastEditors: zyr
 * @LastEditTime: 2021-06-27 16:20:38
-->
<template>
  <div class="wrapper">
    <h2 class="header-tlt">Welcome</h2>
    <h3 class="header-desc">欢迎登录信箱</h3>
    <van-form @submit="onSubmit"  @failed="onFail" :show-error-message="false" validate-first>
      <div class="form-item hack-border-bottom">
        <div class="flex-grow">
          <van-field v-model="phone" placeholder="手机号码"  type="digit"
            name="phone" maxlength="11" autocomplete="off" :error="false"
            :rules="[{
              message: '手机号码格式不正确',
              pattern: /^1[3-9]\d{9}$/
            }]"
          />
        </div>
      </div>
      <div class="form-item hack-border-bottom form-item-last">
        <div class="flex-grow">
          <van-field v-model="password" placeholder="登录密码" name="password" :type="showPass ? 'text' : 'password'"/>
        </div>
        <img :src="showPass ? imgs[1] : imgs[0]" alt="" class="show-pass" @click="changeShowpass"/>
      </div>
      <p class="forget-password"><router-link to="/find">忘记密码？</router-link></p>
      <van-button :disabled="isDisable" :color="'#5460FE'" block native-type="submit"
        :style="isDisable ? {background: '#8F92BB', 'border-color': '#8F92BB'} : ''">登录</van-button>
      <p class="register"><router-link to="/register">创建账号</router-link></p>
    </van-form>
  </div>
</template>
<script>
import API from '@/assets/js/api'
const { login } = API
export default {
  name: 'Login',
  data () {
    return {
      phone: '',
      password: '',
      showPass: false,
      imgs: [
        require('@_img/passShow_no.png'),
        require('@_img/passShow.png')
      ],
      canClick: true
    }
  },
  computed: {
    isDisable: function () {
      if (this.phone && this.password) return false
      else return true
    }
  },
  methods: {
    onFail (info) {
      const { errors: [{ message }] } = info
      this.$toast(message)
    },
    onSubmit (values) {
      if (!this.canClick) return
      this.canClick = false
      this.$axios.post(login, values).then(({ code, msg, model }) => {
        this.canClick = true
        if (code === 0) {
          this.$toast({
            type: 'success',
            message: '登录成功',
            duration: 1500
          })
          const { token, ability } = model
          this.$store.commit('setToken', token)
          localStorage.setItem('token', token)
          this.$store.commit('setAbility', ability)
          localStorage.setItem('ability', ability)
          setTimeout(() => {
            if (this.$store.state.page) {
              if (this.$store.state.page !== '/write' || ability) this.$router.push(this.$store.state.page)
              else this.$router.push(`/home?id=${this.$store.state.id}`)
            } else this.$router.go(-1)
          }, 1500)
        } else this.$toast(msg)
      })
    },
    changeShowpass () {
      const showPass = this.showPass
      this.showPass = !showPass
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.phone = ''
      vm.password = ''
      vm.showPass = false
    })
  }
}
</script>
<style  lang='less'>
@import '../../assets/less/flex.less';
.van-cell{
  padding: 0;
  font-size: .px2rem(32px)[@rem];
}
.van-field__control{
  color: #101112;
}
.van-button{
  height: .px2rem(108px)[@rem];
  border-radius: .px2rem(20px)[@rem];
}
.van-button--disabled{
  opacity: 1;
}
.van-button__text{
  font-size: .px2rem(36px)[@rem];
}
</style>
<style lang='less' scoped>
@import '../../assets/less/flex.less';
@import '../../assets/less/common.less';
</style>
